import {i18n} from "../functions/I18n";

const translationsDE: Record<string, string> = {
    'AC': 'Ascension',
    'AD': 'Andorra',
    'AE': 'Vereinigte Arabische Emirate',
    'AF': 'Afghanistan',
    'AG': 'Antigua und Barbuda',
    'AI': 'Anguilla',
    'AL': 'Albanien',
    'AM': 'Armenien',
    'AN': 'Niederländische Antillen',
    'AO': 'Angola',
    'AQ': 'Antarktis',
    'AR': 'Argentinien',
    'AS': 'Amerikanisch-Samoa',
    'AT': 'Österreich',
    'AU': 'Australien',
    'AW': 'Aruba',
    'AX': 'Alandinseln',
    'AZ': 'Aserbaidschan',
    'BA': 'Bosnien u. Herzegowina',
    'BB': 'Barbados',
    'BD': 'Bangladesch',
    'BE': 'Belgien',
    'BF': 'Burkina Faso',
    'BG': 'Bulgarien',
    'BH': 'Bahrain',
    'BI': 'Burundi',
    'BJ': 'Benin',
    'BL': 'St. Barthélemy',
    'BM': 'Bermuda',
    'BN': 'Brunei Darussalam',
    'BO': 'Bolivien',
    'BQ': 'Britisches Antarktis-Territorium',
    'BR': 'Brasilien',
    'BS': 'Bahamas',
    'BT': 'Bhutan',
    'BV': 'Bouvetinsel',
    'BW': 'Botsuana',
    'BY': 'Belarus',
    'BZ': 'Belize',
    'CA': 'Kanada',
    'CC': 'Kokosinseln',
    'CD': 'Demokratische Republik Kongod',
    'CF': 'Zentralafrikanische Republik',
    'CG': 'Kongo-Brazzavilled',
    'CH': 'Schweiz',
    'CI': 'Elfenbeinküste',
    'CK': 'Cookinseln',
    'CL': 'Chile',
    'CM': 'Kamerun',
    'CN': 'China',
    'CO': 'Kolumbien',
    'CP': 'Clipperton',
    'CR': 'Costa Rica',
    'CS': 'Serbien und Montenegro',
    'CT': 'Phoenixinseln',
    'CU': 'Kuba',
    'CV': 'Kap Verde',
    'CW': 'Curaçao',
    'CX': 'Weihnachtsinsel',
    'CY': 'Zypern',
    'CZ': 'Tschechische Republik',
    'DD': 'Ostdeutschland',
    'DE': 'Deutschland',
    'DG': 'Diego Garcia',
    'DJ': 'Dschibuti',
    'DK': 'Dänemark',
    'DM': 'Dominica',
    'DO': 'Dominikanische Republik',
    'DZ': 'Algerien',
    'EA': 'Ceuta und Melilla',
    'EC': 'Ecuador',
    'EE': 'Estland',
    'EG': 'Ägypten',
    'EH': 'Westsahara',
    'ER': 'Eritrea',
    'ES': 'Spanien',
    'ET': 'Äthiopien',
    'EU': 'Europäische Union',
    'FI': 'Finnland',
    'FJ': 'Fidschi',
    'FK': 'Falklandinseln',
    'FM': 'Mikronesien',
    'FO': 'Färöer',
    'FQ': 'Französische Süd- und Antarktisgebiete',
    'FR': 'Frankreich',
    'FX': 'Metropolitan-Frankreich',
    'GA': 'Gabun',
    'GB': 'Vereinigtes Königreich',
    'GD': 'Grenada',
    'GE': 'Georgien',
    'GF': 'Französisch-Guayana',
    'GG': 'Guernsey',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GL': 'Grönland',
    'GM': 'Gambia',
    'GN': 'Guinea',
    'GP': 'Guadeloupe',
    'GQ': 'Äquatorialguinea',
    'GR': 'Griechenland',
    'GS': 'Südgeorgien und die Südlichen Sandwichinseln',
    'GT': 'Guatemala',
    'GU': 'Guam',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HK': 'Hongkong',
    'HM': 'Heard- und McDonald-Inseln',
    'HN': 'Honduras',
    'HR': 'Kroatien',
    'HT': 'Haiti',
    'HU': 'Ungarn',
    'IC': 'Kanarische Inseln',
    'ID': 'Indonesien',
    'IE': 'Irland',
    'IL': 'Israel',
    'IM': 'Isle of Man',
    'IN': 'Indien',
    'IO': 'Britisches Territorium im Indischen Ozean',
    'IQ': 'Irak',
    'IR': 'Iran',
    'IS': 'Island',
    'IT': 'Italien',
    'JE': 'Jersey',
    'JM': 'Jamaika',
    'JO': 'Jordanien',
    'JP': 'Japan',
    'JT': 'Johnston-Atoll',
    'KE': 'Kenia',
    'KG': 'Kirgisistan',
    'KH': 'Kambodscha',
    'KI': 'Kiribati',
    'KM': 'Komoren',
    'KN': 'St. Kitts und Nevis',
    'KP': 'Nordkorea',
    'KR': 'Republik Korea',
    'KW': 'Kuwait',
    'KY': 'Kaimaninseln',
    'KZ': 'Kasachstan',
    'LA': 'Laos',
    'LB': 'Libanon',
    'LC': 'St. Lucia',
    'LI': 'Liechtenstein',
    'LK': 'Sri Lanka',
    'LR': 'Liberia',
    'LS': 'Lesotho',
    'LT': 'Litauen',
    'LU': 'Luxemburg',
    'LV': 'Lettland',
    'LY': 'Libyen',
    'MA': 'Marokko',
    'MC': 'Monaco',
    'MD': 'Republik Moldau',
    'ME': 'Montenegro',
    'MF': 'St. Martin',
    'MG': 'Madagaskar',
    'MH': 'Marshallinseln',
    'MI': 'Midwayinseln',
    'MK': 'Mazedonien',
    'ML': 'Mali',
    'MM': 'Myanmar',
    'MN': 'Mongolei',
    'MO': 'Macaud',
    'MP': 'Nördliche Marianen',
    'MQ': 'Martinique',
    'MR': 'Mauretanien',
    'MS': 'Montserrat',
    'MT': 'Malta',
    'MU': 'Mauritius',
    'MV': 'Malediven',
    'MW': 'Malawi',
    'MX': 'Mexiko',
    'MY': 'Malaysia',
    'MZ': 'Mosambik',
    'NA': 'Namibia',
    'NC': 'Neukaledonien',
    'NE': 'Niger',
    'NF': 'Norfolkinsel',
    'NG': 'Nigeria',
    'NI': 'Nicaragua',
    'NL': 'Niederlande',
    'NO': 'Norwegen',
    'NP': 'Nepal',
    'NQ': 'Königin-Maud-Land',
    'NR': 'Nauru',
    'NT': 'Neutrale Zone',
    'NU': 'Niue',
    'NZ': 'Neuseeland',
    'OM': 'Oman',
    'PA': 'Panama',
    'PC': 'Treuhandgebiet Pazifische Inseln',
    'PE': 'Peru',
    'PF': 'Französisch-Polynesien',
    'PG': 'Papua-Neuguinea',
    'PH': 'Philippinen',
    'PK': 'Pakistan',
    'PL': 'Polen',
    'PM': 'St. Pierre und Miquelon',
    'PN': 'Pitcairn',
    'PR': 'Puerto Rico',
    'PS': 'Palästinensische Autonomiegebiete',
    'PT': 'Portugal',
    'PU': 'U.S. Miscellaneous Pacific Islands',
    'PW': 'Palau',
    'PY': 'Paraguay',
    'PZ': 'Panamakanalzone',
    'QA': 'Katar',
    'QO': 'Äußeres Ozeanien',
    'RE': 'Réunion',
    'RO': 'Rumänien',
    'RS': 'Serbien',
    'RU': 'Russische Föderation',
    'RW': 'Ruanda',
    'SA': 'Saudi-Arabien',
    'SB': 'Salomonen',
    'SC': 'Seychellen',
    'SD': 'Sudan',
    'SE': 'Schweden',
    'SG': 'Singapur',
    'SH': 'St. Helena',
    'SI': 'Slowenien',
    'SJ': 'Svalbard und Jan Mayen',
    'SK': 'Slowakei',
    'SL': 'Sierra Leone',
    'SM': 'San Marino',
    'SN': 'Senegal',
    'SO': 'Somalia',
    'SR': 'Suriname',
    'ST': 'São Tomé und Príncipe',
    'SU': 'Sowjetunion',
    'SV': 'El Salvador',
    'SY': 'Syrien',
    'SZ': 'Swasiland',
    'TA': 'Tristan da Cunha',
    'TC': 'Turks- und Caicosinseln',
    'TD': 'Tschad',
    'TF': 'Französische Süd- und Antarktisgebiete',
    'TG': 'Togo',
    'TH': 'Thailand',
    'TJ': 'Tadschikistan',
    'TK': 'Tokelau',
    'TL': 'Osttimor',
    'TM': 'Turkmenistan',
    'TN': 'Tunesien',
    'TO': 'Tonga',
    'TR': 'Türkei',
    'TT': 'Trinidad und Tobago',
    'TV': 'Tuvalu',
    'TW': 'Taiwan',
    'TZ': 'Tansania',
    'UA': 'Ukraine',
    'UG': 'Uganda',
    'UM': 'United States Minor Outlying Islands',
    'US': 'Vereinigte Staaten',
    'UY': 'Uruguay',
    'UZ': 'Usbekistan',
    'VA': 'Vatikanstadt',
    'VC': 'St. Vincent und die Grenadinen',
    'VD': 'Nordvietnam',
    'VE': 'Venezuela',
    'VG': 'Britische Jungferninseln',
    'VI': 'Amerikanische Jungferninseln',
    'VN': 'Vietnam',
    'VU': 'Vanuatu',
    'WF': 'Wallis und Futuna',
    'WK': 'Wake',
    'WS': 'Samoa',
    'YD': 'Volksdemokratische Republik Jemen',
    'YE': 'Jemen',
    'YT': 'Mayotte',
    'ZA': 'Südafrika',
    'ZM': 'Sambia',
    'ZW': 'Simbabwe'
};

const translationsEN: Record<string, string> = {
    'AC': 'Ascension',
    'AD': 'Andorra',
    'AE': 'United Arab Emirates',
    'AF': 'Afghanistan',
    'AG': 'Antigua and Barbuda',
    'AI': 'Anguilla',
    'AL': 'Albania',
    'AM': 'Armenia',
    'AN': 'Netherlands Antilles',
    'AO': 'Angola',
    'AQ': 'Antarctica',
    'AR': 'Argentina',
    'AS': 'American Samoa',
    'AT': 'Austria',
    'AU': 'Australia',
    'AW': 'Aruba',
    'AX': 'Aland Islands',
    'AZ': 'Azerbaijan',
    'BA': 'Bosnia and Herzegovina',
    'BB': 'Barbados',
    'BD': 'Bangladesh',
    'BE': 'Belgium',
    'BF': 'Burkina Faso',
    'BG': 'Bulgaria',
    'BH': 'Bahrain',
    'BI': 'Burundi',
    'BJ': 'Benin',
    'BL': 'St. Barthélemy',
    'BM': 'Bermuda',
    'BN': 'Brunei Darussalam',
    'BO': 'Bolivia',
    'BQ': 'British Antarctic Territory',
    'BR': 'Brazil',
    'BS': 'Bahamas',
    'BT': 'Bhutan',
    'BV': 'Bouvet Island',
    'BW': 'Botswana',
    'BY': 'Belarus',
    'BZ': 'Belize',
    'CA': 'Canada',
    'CC': 'Cocos Islands',
    'CD': 'Democratic Republic of the Congo',
    'CF': 'Central African Republic',
    'CG': 'Congo-Brazzavilled',
    'CH': 'Switzerland',
    'CI': 'Ivory Coast',
    'CK': 'Cook Islands',
    'CL': 'Chile',
    'CM': 'Cameroon',
    'CN': 'China',
    'CO': 'Colombia',
    'CP': 'Clipperton',
    'CR': 'Costa Rica',
    'CS': 'Serbia and Montenegro',
    'CT': 'Phoenix Islands',
    'CU': 'Cuba',
    'CV': 'Cape Verde',
    'CW': 'Curaçao',
    'CX': 'Christmas Island',
    'CY': 'Cyprus',
    'CZ': 'Czech Republic',
    'DD': 'East Germany',
    'DE': 'Germany',
    'DG': 'Diego Garcia',
    'DJ': 'Djibouti',
    'DK': 'Denmark',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'DZ': 'Algeria',
    'EA': 'Ceuta and Melilla',
    'EC': 'Ecuador',
    'EE': 'Estonia',
    'EG': 'Egypt',
    'EH': 'Western Sahara',
    'ER': 'Eritrea',
    'ES': 'Spain',
    'ET': 'Ethiopia',
    'EU': 'European Union',
    'FI': 'Finland',
    'FJ': 'Fiji',
    'FK': 'Falkland Islands',
    'FM': 'Micronesia',
    'FO': 'Faroe Islands',
    'FQ': 'French Southern and Antarctic Territories',
    'FR': 'France',
    'FX': 'Metropolitan France',
    'GA': 'Gabon',
    'GB': 'United Kingdom',
    'GD': 'Grenada',
    'GE': 'Georgia',
    'GF': 'French Guiana',
    'GG': 'Guernsey',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GL': 'Greenland',
    'GM': 'Gambia',
    'GN': 'Guinea',
    'GP': 'Guadeloupe',
    'GQ': 'Equatorial Guinea',
    'GR': 'Greece',
    'GS': 'South Georgia and the South Sandwich Islands',
    'GT': 'Guatemala',
    'GU': 'Guam',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HK': 'Hong Kong',
    'HM': 'Heard and McDonald Islands',
    'HN': 'Honduras',
    'HR': 'Croatia',
    'HT': 'Haiti',
    'HU': 'Hungary',
    'IC': 'Canary Islands',
    'ID': 'Indonesia',
    'IE': 'Ireland',
    'IL': 'Israel',
    'IM': 'Isle of Man',
    'IN': 'India',
    'IO': 'British Indian Ocean Territory',
    'IQ': 'Iraq',
    'IR': 'Iran',
    'IS': 'Iceland',
    'IT': 'Italy',
    'JE': 'Jersey',
    'JM': 'Jamaica',
    'JO': 'Jordan',
    'JP': 'Japan',
    'JT': 'Johnston Atoll',
    'KE': 'Kenya',
    'KG': 'Kyrgyzstan',
    'KH': 'Cambodia',
    'KI': 'Kiribati',
    'KM': 'Comoros',
    'KN': 'St. Kitts and Nevis',
    'KP': 'North Korea',
    'KR': 'Republic of Korea',
    'KW': 'Kuwait',
    'KY': 'Cayman Islands',
    'KZ': 'Kazakhstan',
    'LA': 'Laos',
    'LB': 'Lebanon',
    'LC': 'St. Lucia',
    'LI': 'Liechtenstein',
    'LK': 'Sri Lanka',
    'LR': 'Liberia',
    'LS': 'Lesotho',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'LV': 'Latvia',
    'LY': 'Libya',
    'MA': 'Morocco',
    'MC': 'Monaco',
    'MD': 'Republic of Moldova',
    'ME': 'Montenegro',
    'MF': 'St. Martin',
    'MG': 'Madagascar',
    'MH': 'Marshall Islands',
    'MI': 'Midway Islands',
    'MK': 'Macedonia',
    'ML': 'Mali',
    'MM': 'Myanmar',
    'MN': 'Mongolia',
    'MO': 'Macaud',
    'MP': 'Northern Mariana Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MS': 'Montserrat',
    'MT': 'Malta',
    'MU': 'Mauritius',
    'MV': 'Maldives',
    'MW': 'Malawi',
    'MX': 'Mexico',
    'MY': 'Malaysia',
    'MZ': 'Mozambique',
    'NA': 'Namibia',
    'NC': 'New Caledonia',
    'NE': 'Niger',
    'NF': 'Norfolk Island',
    'NG': 'Nigeria',
    'NI': 'Nicaragua',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'NP': 'Nepal',
    'NQ': 'Queen Maud Land',
    'NR': 'Nauru',
    'NT': 'Neutral Zone',
    'NU': 'Niue',
    'NZ': 'New Zealand',
    'OM': 'Oman',
    'PA': 'Panama',
    'PC': 'Trust Territory of the Pacific Islands',
    'PE': 'Peru',
    'PF': 'French Polynesia',
    'PG': 'Papua New Guinea',
    'PH': 'Philippines',
    'PK': 'Pakistan',
    'PL': 'Poland',
    'PM': 'St. Pierre and Miquelon',
    'PN': 'Pitcairn',
    'PR': 'Puerto Rico',
    'PS': 'Palestinian Territories',
    'PT': 'Portugal',
    'PU': 'U.S. Miscellaneous Pacific Islands',
    'PW': 'Palau',
    'PY': 'Paraguay',
    'PZ': 'Panama Canal Zone',
    'QA': 'Qatar',
    'QO': 'Outer Oceania',
    'RE': 'Réunion',
    'RO': 'Romania',
    'RS': 'Serbia',
    'RU': 'Russian Federation',
    'RW': 'Rwanda',
    'SA': 'Saudi Arabia',
    'SB': 'Solomon Islands',
    'SC': 'Seychelles',
    'SD': 'Sudan',
    'SE': 'Sweden',
    'SG': 'Singapore',
    'SH': 'St. Helena',
    'SI': 'Slovenia',
    'SJ': 'Svalbard and Jan Mayen',
    'SK': 'Slovakia',
    'SL': 'Sierra Leone',
    'SM': 'San Marino',
    'SN': 'Senegal',
    'SO': 'Somalia',
    'SR': 'Suriname',
    'ST': 'São Tomé and Príncipe',
    'SU': 'Soviet Union',
    'SV': 'El Salvador',
    'SY': 'Syria',
    'SZ': 'Swaziland',
    'TA': 'Tristan da Cunha',
    'TC': 'Turks and Caicos Islands',
    'TD': 'Chad',
    'TF': 'French Southern and Antarctic Territories',
    'TG': 'Togo',
    'TH': 'Thailand',
    'TJ': 'Tajikistan',
    'TK': 'Tokelau',
    'TL': 'East Timor',
    'TM': 'Turkmenistan',
    'TN': 'Tunisia',
    'TO': 'Tonga',
    'TR': 'Turkey',
    'TT': 'Trinidad and Tobago',
    'TV': 'Tuvalu',
    'TW': 'Taiwan',
    'TZ': 'Tanzania',
    'UA': 'Ukraine',
    'UG': 'Uganda',
    'UM': 'United States Minor Outlying Islands',
    'US': 'United States',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VA': 'Vatican City',
    'VC': 'St. Vincent and the Grenadines',
    'VD': 'North Vietnam',
    'VE': 'Venezuela',
    'VG': 'British Virgin Islands',
    "VI": "American Virgin Islands",
    'VN': 'Vietnam',
    'VU': 'Vanuatu',
    'WF': 'Wallis and Futuna',
    'WK': 'Wake',
    'WS': 'Samoa',
    'YD': 'People\'s Democratic Republic of Yemen',
    'YE': 'Yemen',
    'YT': 'Mayotte',
    'ZA': 'South Africa',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe'
};

const translationsES: Record<string, string> = {
    'AC': 'Ascensión',
    'AD': 'Andorra',
    'AE': 'Emiratos Árabes Unidos',
    'AF': 'Afganistán',
    'AG': 'Antigua y Barbuda',
    'AI': 'Anguila',
    'AL': 'Albania',
    'AM': 'Armenia',
    'AN': 'Antillas Neerlandesas',
    'AO': 'Angola',
    'AQ': 'Antártida',
    'AR': 'Argentina',
    'AS': 'Samoa Americana',
    'AT': 'Austria',
    'AU': 'Australia',
    'AW': 'Aruba',
    'AX': 'Islas Aland',
    'AZ': 'Azerbaiyán',
    'BA': 'Bosnia y Herzegovina',
    'BB': 'Barbados',
    'BD': 'Bangladesh',
    'BE': 'Bélgica',
    'BF': 'Burkina Faso',
    'BG': 'Bulgaria',
    'BH': 'Bahréin',
    'BI': 'Burundi',
    'BJ': 'Benín',
    'BL': 'San Bartolomé',
    'BM': 'Bermudas',
    'BN': 'Brunei Darussalam',
    'BO': 'Bolivia',
    'BQ': 'Territorio Antártico Británico',
    'BR': 'Brasil',
    'BS': 'Bahamas',
    'BT': 'Bután',
    'BV': 'Isla Bouvet',
    'BW': 'Botsuana',
    'BY': 'Belarús',
    'BZ': 'Belice',
    'CA': 'Canadá',
    'CC': 'Islas Cocos',
    'CD': 'República Democrática del Congo',
    'CF': 'República Centroafricana',
    'CG': 'Congo-Brazzaville',
    'CH': 'Suiza',
    'CI': 'Costa de Marfil',
    'CK': 'Islas Cook',
    'CL': 'Chile',
    'CM': 'Camerún',
    'CN': 'China',
    'CO': 'Colombia',
    'CP': 'Clipperton',
    'CR': 'Costa Rica',
    'CS': 'Serbia y Montenegro',
    'CT': 'Islas Fénix',
    'CU': 'Cuba',
    'CV': 'Cabo Verde',
    'CW': 'Curazao',
    'CX': 'Isla Christmas',
    'CY': 'Chipre',
    'CZ': 'República Checa',
    'DD': 'Alemania Oriental',
    'DE': 'Alemania',
    'DG': 'Diego García',
    'DJ': 'Yibuti',
    'DK': 'Dinamarca',
    'DM': 'Dominica',
    'DO': 'República Dominicana',
    'DZ': 'Argelia',
    'EA': 'Ceuta y Melilla',
    'EC': 'Ecuador',
    'EE': 'Estonia',
    'EG': 'Egipto',
    'EH': 'Sáhara Occidental',
    'ER': 'Eritrea',
    'ES': 'España',
    'ET': 'Etiopía',
    'UE': 'Unión Europea',
    'FI': 'Finlandia',
    'FJ': 'Fiyi',
    'FK': 'Islas Malvinas',
    'FM': 'Micronesia',
    'FO': "Islas Feroe",
    'FQ': 'Territorios Australes y Antárticos Franceses',
    'FR': 'Francia',
    'FX': 'Francia metropolitana',
    'GA': 'Gabón',
    'GB': 'Reino Unido',
    'GD': 'Granada',
    'GE': 'Georgia',
    'GF': 'Guayana Francesa',
    'GG': 'Guernesey',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GL': 'Groenlandia',
    'GM': 'Gambia',
    'GN': 'Guinea',
    'GP': 'Guadalupe',
    'GQ': 'Guinea Ecuatorial',
    'GR': 'Grecia',
    'GS': 'Georgia del Sur e Islas Sandwich del Sur',
    'GT': 'Guatemala',
    'GU': 'Guam',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HK': 'Hong Kong',
    'HM': 'Islas Heard y McDonald',
    'HN': 'Honduras',
    'HR': 'Croacia',
    'HT': 'Haití',
    'HU': 'Hungría',
    'IC': 'Islas Canarias',
    'ID': 'Indonesia',
    'IE': 'Irlanda',
    'IL': 'Israel',
    'IM': 'Isla de Man',
    'IN': "India",
    'IO': 'Territorio Británico del Océano Índico',
    'IQ': 'Iraq',
    'IR': 'Irán',
    'IS': 'Islandia',
    'IT': 'Italia',
    'JE': 'Jersey',
    'JM': 'Jamaica',
    'JO': 'Jordania',
    'JP': 'Japón',
    'JT': 'Atolón Johnston',
    'KE': 'Kenia',
    'KG': 'Kirguistán',
    'KH': 'Camboya',
    'KI': 'Kiribati',
    'KM': 'Comoras',
    'KN': 'San Cristóbal y Nieves',
    'KP': 'Corea del Norte',
    'KR': 'República de Corea',
    'KW': 'Kuwait',
    'KY': "Islas Caimán",
    'KZ': 'Kazajstán',
    'LA': 'Laos',
    'LB': 'Líbano',
    'LC': 'Santa Lucía',
    'LI': 'Liechtenstein',
    'LK': 'Sri Lanka',
    'LR': 'Liberia',
    'LS': 'Lesotho',
    'LT': 'Lituania',
    'LU': 'Luxemburgo',
    'LV': 'Letonia',
    'LY': 'Libia',
    'MA': 'Marruecos',
    'MC': 'Mónaco',
    'MD': 'República de Moldavia',
    'ME': 'Montenegro',
    'MF': 'San Martín',
    'MG': 'Madagascar',
    'MH': 'Islas Marshall',
    'MI': 'Islas Midway',
    'MK': 'Macedonia',
    'ML': 'Malí',
    'MM': 'Myanmar',
    'MN': 'Mongolia',
    'MO': 'Macao',
    'MP': 'Northern Mariana Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MS': 'Montserrat',
    'MT': 'Malta',
    'MU': 'Mauritius',
    'MV': 'Maldives',
    'MW': 'Malawi',
    'MX': 'Mexico',
    'MY': 'Malaysia',
    'MZ': 'Mozambique',
    'NA': 'Namibia',
    'NC': 'New Caledonia',
    'NE': 'Niger',
    'NF': 'Norfolk Island',
    'NG': 'Nigeria',
    'NI': 'Nicaragua',
    'NL': 'Netherlands',
    'NO': 'Norway',
    'NP': 'Nepal',
    'NQ': 'Queen Maud Land',
    'NR': 'Nauru',
    'NT': 'Neutral Zone',
    'NU': 'Niue',
    'NZ': 'New Zealand',
    'OM': 'Oman',
    'PA': 'Panama',
    'PC': 'Trust Territory of the Pacific Islands',
    'PE': 'Peru',
    'PF': 'French Polynesia',
    'PG': 'Papua New Guinea',
    'PH': 'Philippines',
    'PK': 'Pakistan',
    'PL': 'Poland',
    'PM': 'St. Pierre and Miquelon',
    'PN': 'Pitcairn',
    'PR': 'Puerto Rico',
    'PS': 'Palestinian Territories',
    'PT': 'Portugal',
    'PU': 'Miscelánea de EE.UU. para las Islas del Pacífico',
    'PW': 'Palaos',
    'PY': 'Paraguay',
    'PZ': 'Zona del Canal de Panamá',
    'QA': 'Qatar',
    'QO': 'Oceanía Exterior',
    'RE': 'Reunión',
    'RO': 'Rumanía',
    'RS': 'Serbia',
    'RU': 'Federación de Rusia',
    'RW': 'Ruanda',
    'SA': 'Arabia Saudí',
    'SB': 'Islas Salomón',
    'SC': 'Seychelles',
    'SD': 'Sudán',
    'SE': 'Suecia',
    'SG': 'Singapur',
    'SH': 'Santa Elena',
    'SI': 'Eslovenia',
    'SJ': 'Svalbard y Jan Mayen',
    'SK': 'Eslovaquia',
    'SL': 'Sierra Leona',
    'SM': 'San Marino',
    'SN': 'Senegal',
    'SO': 'Somalia',
    'SR': 'Surinam',
    'ST': 'Santo Tomé y Príncipe',
    'SU': 'Unión Soviética',
    'SV': 'El Salvador',
    'SY': 'Siria',
    'SZ': 'Suazilandia',
    'TA': 'Tristán da Cunha',
    'TC': 'Islas Turcas y Caicos',
    'TD': 'Chad',
    'TF': 'Territorios Australes y Antárticos Franceses',
    'TG': 'Togo',
    'TH': 'Tailandia',
    'TJ': 'Tayikistán',
    'TK': 'Tokelau',
    'TL': 'Timor Oriental',
    'TM': 'Turkmenistán',
    'TN': 'Túnez',
    'TO': 'Tonga',
    'TR': 'Turquía',
    'TT': 'Trinidad y Tobago',
    'TV': 'Tuvalu',
    'TW': 'Taiwán',
    'TZ': 'Tanzania',
    'UA': 'Ucrania',
    'UG': 'Uganda',
    'UM': 'Islas periféricas menores de los Estados Unidos',
    'US': "Estados Unidos",
    'UY': 'Uruguay',
    'UZ': 'Uzbekistán',
    'VA': 'Ciudad del Vaticano',
    'VC': 'San Vicente y las Granadinas',
    'VD': 'Vietnam del Norte',
    'VE': 'Venezuela',
    "VG": "Islas Vírgenes Británicas",
    "VI": "Islas Vírgenes Americanas",
    'VN': 'Vietnam',
    'VU': 'Vanuatu',
    'WF': 'Wallis y Futuna',
    'WK': 'Wake',
    'WS': 'Samoa',
    'YD': 'República Democrática Popular de Yemen',
    'YE': 'Yemen',
    'YT': 'Mayotte',
    'ZA': 'Sudáfrica',
    'ZM': 'Zambia',
    'ZW': 'Zimbabue'
};

const supportedCountries = [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IM',
    'IN',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PR',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TG',
    'TH',
    'TJ',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'XK',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW'
];

/**
 * Translates the given country code into a German language.
 *
 * @param countryCode
 * @param language
 */
const translateCountryCode = (countryCode: string, language: string = i18n.language): string =>
{
    if (!['de', 'en', 'es'].includes(language)) {
        return countryCode;
    }

    let translations = null;

    switch (language) {
        case 'de':
            translations = translationsDE;
            break;
        case 'en':
            translations = translationsEN;
            break;
        case 'es':
            translations = translationsES;
            break;
        default:
            translations = translationsEN;
            break;
    }

    if (!translations) {
        return countryCode;
    }

    if (!translations.hasOwnProperty(countryCode)) {
        return countryCode;
    }

    return translations[countryCode];
}

/**
 * Returns a country array (for example for select tags).
 *
 * @param language
 */
const getCountryArray = (language: string = i18n.language): { code: string, name: string }[] =>
{
    let countryArray: { code: string, name: string }[] = [];

    supportedCountries.forEach((code: string) => {
        countryArray.push({ code: code, name: translateCountryCode(code, language) });
    });

    countryArray.sort((a, b) => a.name.localeCompare(b.name));

    return countryArray;
}

/*
 * Export functions.
 */
export {
    translateCountryCode,
    getCountryArray
}
